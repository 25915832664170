<template>
  <div>
    <!-- 中文 -->
    <div v-if="$store.state.show">
      <h2>其他问题</h2>
      <h3>1. TiSDK支持PC、Web、Unity？</h3>
      <p>答：暂不支持。</p>
      <h3>2. 最大支持的人脸数是多少？</h3>
      <p>答：最多5张人脸，但是考虑到性能和功耗，建议控制在3人及以内。</p>
      <h3>3. 测试Key与正式Key区别？</h3>
      <p>
        答：Key分为两种，测试Key和正式Key。测试Key使用鉴权服务器不稳定，建议不要线上环境使用，正式Key可进行线上发布使用，更稳定。测试Key有弹窗提示。付费后转正式Key不需要更换秘钥，拓幻后台更改即可。
      </p>
      <h3>4. SDK是否支持对本地图片或视频做处理？</h3>
      <p>
        答：支持，但是视频解码转格式的过程需要客户自行实现，Demo有提供处理示例可供参考。
      </p>
      <h3>5. 针对直播、音视频、短视频等技术提供商，我们SDK是否支持接入？</h3>
      <p>
        答：第三方有开放接口就可以支持，目前已有腾讯云、七牛云、金山云、声网、环信、网易云、即构科技、阿里云、三体云、融云平台集成Demo。
      </p>
    </div>
    <!-- 英文 -->
    <div v-else>
      <h2>Other questions</h2>
      <h3>1. TiSDK supports PC, Web, Unity?</h3>
      <p>A: Not supported yet.</p>
      <h3>2. What is the maximum number of faces supported?</h3>
      <p>
        A: A maximum of 5 faces, but considering the performance and power
        consumption, it is recommended to control within 3 people.
      </p>
      <h3>3. What is the difference between a test key and a formal key?</h3>
      <p>
        A: Key is divided into two types, testing Key and formal Key.The
        authentication server used by the test key is unstable. It is not
        recommended to use the online environment. Formal keys can be published
        online and in a stable state.Test Key has a pop-up prompt.You don’t need
        to change the secret key to transfer to the official Key after paying,
        just change it in the background.
      </p>
      <h3>4. Does SDK support processing this map or video?</h3>
      <p>
        A: Yes, but the process of video decoding to format needs to be done by
        the client himself. Demo has provided some processing examples for
        reference.
      </p>
      <h3>
        5. Does our SDK support access for technology providers such as live
        broadcasting, audio and video, short video, etc?
      </h3>
      <p>
        A: Third parties can support it with open interfaces. At present,
        Tencent Cloud, Qiniu Cloud, Kingsoft Cloud, Agore, Easemob, NetEase
        Cloud, Zego, Alibaba Cloud, SanTi Cloud and Rong Cloud have integrated
        Demo.
      </p>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
h2 {
  font-size: 20px;
  font-weight: 500;
  color: #3c4858;
}
h3 {
  font-size: 16px;
  font-weight: 500;
  color: #3c4858;
  margin-top: 20px;
  line-height: 32px;
}
p {
  width: 763px;
  font-size: 16px;
  line-height: 32px;
  font-weight: 400;
  color: #3c4858;
}
</style>